/* eslint-disable jsx-a11y/iframe-has-title */
import type { AppProps } from 'next/app'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import '../app/styles/globals.sass'
import { GtmNoscriptTag, GtmScriptTag } from '../packages/@necktip/next/gtm'

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<QueryClientProvider client={queryClient}>
			<GtmScriptTag />
			<GtmNoscriptTag />
			{/* <Script strategy="beforeInteractive" src="https://www.google.com/recaptcha/api.js" />
			<Script
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `;(function () {
								var next = function () {
									var response = document.getElementById('g-recaptcha-response')

									if (!(response && response.value.trim().length)) {
										var settings = document.getElementsByName('captcha_settings')
										if (settings.length) {
											var elems = JSON.parse(settings[0].value)
											elems['ts'] = JSON.stringify(new Date().getTime())
											settings[0].value = JSON.stringify(elems)
										}
									}

									setTimeout(next, 500)
								}
								next()
							})();`,
				}}
			/> */}
			<Component {...pageProps} />
		</QueryClientProvider>
	)
}
export default MyApp
