import Script from 'next/script'
import { getConfig } from '../../../../app/next/config'

function getGtmTrackingId(): string | null {
	const config = getConfig()?.publicRuntimeConfig ?? {}

	if ('GTM_TRACKING_ID' in config) {
		return config?.GTM_TRACKING_ID ?? null
	}

	return null
}

export function GtmScriptTag(props: { id?: string }) {
	const GTM_TRACKING_ID = props.id ?? getGtmTrackingId()

	return GTM_TRACKING_ID ? (
		<Script strategy="afterInteractive">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','${GTM_TRACKING_ID}');`}</Script>
	) : null
}

export function GtmNoscriptTag(props: { id?: string }) {
	const GTM_TRACKING_ID = props.id ?? getGtmTrackingId()

	return (
		<noscript
			key="gtm-noscript"
			dangerouslySetInnerHTML={{
				__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
			}}></noscript>
	)
}
